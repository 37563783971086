import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

class Return extends React.Component {
  render() {
    return (
      <div className="termsAndConditions">
        <Helmet>
          <title>Pravila zaštite podataka i privatnosti</title>
        </Helmet>
        <Container>
          <Link className="terms" to="/">
            Početna
          </Link>
          <h1 className="pageTitle">Povrat novca i proizvoda</h1>
          <p>
            Sukladno Zakonu o zaštiti potrošača korisnik ima pravo Ugovor
            jednostrano raskinuti u roku od 14 dana bez navođenja razloga. Da bi
            ostvario pravo na jednostrani raskid Ugovora, korisnik nas mora
            obavijestiti o svojoj odluci o jednostranom raskidu ugovora i to
            nedvosmislenom izjavom poslanom poštom ili elektroničkom poštom, u
            kojoj ćete navesti svoje ime i prezime, adresu, broj telefona,
            telefaksa ili adresu elektroničke pošte. <br /> Ako korisnik
            jednostrano raskine Ugovor, izvršit ćemo povrat novca koji smo od
            korisnika primili, isključujući troškove isporuke i to bez
            odgađanja, a najkasnije u roku od 14 dana od dana kada smo zaprimili
            korisnikovu odluku o jednostranom raskidu ugovora. <br /> Trošak
            zamjene proizvoda snosi kupac u istom iznosu u kojem mu je roba
            dostavljena, osim u slučaju kada je za troškove povrata proizvoda
            moguće odabrati jeftiniju opciju, u tom slučaju kupac može snositi
            troškove zamjene proizvoda u iznosu manjem od iznosa u kojem mu je
            roba dostavljena. U slučaju raskida ugovora, svaka je strana dužna
            vratiti drugoj ono što je primila na temelju Ugovora, osim za
            troškove navedene pod stavkom Prema članku 72. Zakona o zaštiti
            potrošača, dužni ste primljenu robu vratiti na adresu Dunavska 14,
            35000 Slavonski Brod, Hrvatska. <br /> Povrat novca bit će izvršen
            na isti način na koji ste Vi izvršili uplatu. Povrat novca možemo
            izvršiti tek nakon što nam roba bude vraćena. Korisnik robu treba
            poslati ili je predati nama bez nepotrebnog odgađanja, a u svakom
            slučaju najkasnije u roku od 14 dana od dana kada nam je uputio
            odluku o jednostranom raskidu ugovora. U slučaju korisnikovog
            jednostranog raskida ugovora izravne troškove povrata robe mora
            snositi korisnik. U slučaju korisnikovog jednostranog raskida
            ugovora korisnik je odgovoran za svako umanjenje vrijednosti robe
            koju vraća, koje je rezultat rukovanja robom, osim onog koje je bilo
            potrebno za utvrđivanje prirode, obilježja i funkcionalnosti robe.{" "}
            <br /> Za materijalne nedostatke odgovaramo sukladno odredbama
            Zakona o obveznim odnosima. Ukoliko je došlo do pogreške prilikom
            pakiranja naručenih proizvoda te korisnik nije dobio naručeni
            proizvod već neki drugi, u tom slučaju, korisnik vraća krivo
            isporučen proizvod na trošak Terraforma-a koji se obvezuje
            isporučiti naručeni proizvod ili izvršiti povrat novca (sukladno
            zahtjevu korisnika) u roku od 14 dana od zaprimljenog krivo
            isporučenog artikla. <br /> Jedini preduvjet jednostranog raskida
            Ugovora jest da roba nije korištena te da se nalazi u originalnoj
            ambalaži s originalnim etiketama.
          </p>
        </Container>
      </div>
    );
  }
}

export default Return;
